/* eslint-disable react-hooks/exhaustive-deps */
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ISelectorOption } from 'types/ISelectorOption';
import { format } from 'date-fns';
import fromatDurationString from 'client/helpers/fromatDurationString';
interface IProductPriceFilterValues {
  price?: string | number;
  ['start-date']: string;
  from: ISelectorOption | string;
  fromAvailability: ISelectorOption;
  numberOfNights?: ISelectorOption | string;
}
export const useSelectListModalConatiner = (froms: ISelectorOption[]) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();

  const [token, setToken] = useState('');

  const { cataloguePricesAvailability } = useAppSelector(
    ({ productReducer }) => productReducer,
  );

  const { permalink } = useParams<{ permalink: string }>();

  const urlParams: { from: string; 'number-of-nights': string } = useMemo(
    () =>
      queryStringToObject<{ from: string; 'number-of-nights': string }>(
        decodeURI(location.search),
      ),
    [location.search],
  );

  const { cataloguePrices, product } = useAppSelector(({ productReducer }) => productReducer);

  const productCode = product?._$id.split('-').pop() as string;

  const pricesList = cataloguePrices && cataloguePrices.prices;

  const minimumPrice =
    cataloguePrices &&
    cataloguePrices.prices
      .reduce(
        (prev, curr) => (Number(curr.value) < prev ? Number(curr.value) : prev),
        Infinity,
      )
      .toString();
  const initialValues = useMemo(() => {
    const from =
      (urlParams.from &&
        cataloguePricesAvailability?.find((el) => el.from?.value === urlParams.from)?.from) ||
      cataloguePricesAvailability?.find((el) => el.from?.value === cataloguePrices?.from.value)
        ?.from ||
      cataloguePricesAvailability?.[0].from;

    const numberOfNights =
      (urlParams['number-of-nights'] &&
        cataloguePricesAvailability
          ?.find((el) => el.from?.value === from?.value)
          ?.nights.find((e) => e.value === urlParams['number-of-nights'])) ||
      cataloguePricesAvailability?.find((el) => el.from?.value === cataloguePrices?.from.value)
        ?.nights?.[0] ||
      cataloguePricesAvailability?.find((el) => el.from?.value === from?.value)?.nights?.[0];

    const startDate =
      pricesList?.length !== 0 &&
      format(
        new Date(pricesList?.filter((e) => e.value === minimumPrice)?.[0].date || 0),
        'yyyy-MM-dd',
      );

    const price = pricesList && pricesList.filter((e) => e.value === minimumPrice)?.[0]?.value;

    return { from, numberOfNights, startDate, price };
  }, [urlParams, cataloguePricesAvailability, pricesList, minimumPrice]);

  const formMethods = useForm<IProductPriceFilterValues>({
    defaultValues: {
      from: initialValues.from && initialValues.from.label,
      numberOfNights: initialValues.numberOfNights && initialValues.numberOfNights?.label,
      'start-date': initialValues.startDate ? initialValues.startDate : '',
    },
  });

  const { watch, handleSubmit } = formMethods;

  const fromAvailability = cataloguePricesAvailability?.map((e) => e.from);

  const nightAvailability = cataloguePricesAvailability?.find(
    (e) => e.from.label === watch('from'),
  );

  useEffect(() => {
    fetch(`${process.env.RAZZLE_HOST}/api/v1/product-info/token-soap`)
      .then((res) => res.json())
      .then((data) => setToken(data));
  });
  console.log(
    '%csrcclientcontainersSelectListModalConatiner\ts:103 RAZZLE_HOST',
    'color: #007acc;',
    process.env.RAZZLE_HOST,
  );

  // -------------------
  const urlFrom = fromAvailability?.find((e) => e?.label === watch('from'))?.value;
  const urlNumberOfNights =
    nightAvailability?.nights?.find(
      (e) =>
        fromatDurationString(e?.label)
          .replace(/Night/g, t('productPageInfos.Nuits'))
          .replace(/Day/g, t('productPageInfos.Jours')) === watch('numberOfNights'),
    )?.value || initialValues.numberOfNights?.value;
  const urlStartDate = watch('start-date');
  // -------------------

  const _onSubmit = () => {
    // eslint-disable-next-line max-len
    const url = `https://kepler.mypgi.fr/extranet/sso?token=${token}&produit_id=${productCode}&begin=${urlStartDate}&from=${urlFrom}`;
    // const url = `/product/${permalink}/quotation?start-date=${urlStartDate}&from=${urlFrom}&number-of-nights=${urlNumberOfNights}&adult-count=${watch(
    //   'adultCount',
    // )}&child-count=${watch('childCount')}`;

    window.location.replace(url);
  };

  useEffect(() => {
    const url = `/product/${permalink}?from=${urlFrom}&number-of-nights=${
      nightAvailability?.nights?.find(
        (e) =>
          fromatDurationString(e?.label)
            .replace(/Night/g, t('productPageInfos.Nuits'))
            .replace(/Day/g, t('productPageInfos.Jours')) === watch('numberOfNights'),
      )?.value
    }`;

    history.replace(url);
  }, [permalink, urlNumberOfNights, urlFrom]);
  const [watchPrice, setWatchPrice] = useState(initialValues.price);

  const nightAvailabilityNewLabel = {
    ...nightAvailability,
    nights: nightAvailability?.nights.map((el) => ({
      ...el,
      label: fromatDurationString(el?.label)
        .replace(/Night/g, t('productPageInfos.Nuits'))
        .replace(/Day/g, t('productPageInfos.Jours')),
    })),
  };

  return {
    formMethods,
    initialValues,
    fromAvailability,
    nightAvailability: nightAvailabilityNewLabel,
    minimumPrice,
    pricesList,
    permalink,
    _onSubmit,
    handleSubmit,
    t,
    watchPrice,
    setWatchPrice,
  };
};
