import { getCompressedImageUrl } from 'client/helpers/getCompressedImageUrl';
import * as React from 'react';
import Slider, { Settings } from 'react-slick';
import LazyImage from './LazyImage';

interface IProps {
  media?: string[];
  autoPlay?: boolean;
}
interface SliderTypes {
  className?: string;
  style?: StyleSheet;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

const SampleNextArrow: React.FC<SliderTypes> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style, display: 'block', background: 'none', right: '0px' }}
      onClick={onClick}
    />
  );
};

const SamplePrevArrow: React.FC<SliderTypes> = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{
        ...style,
        display: 'block',
        background: 'none',
        left: '0px',
      }}
      onClick={onClick}
    />
  );
};

const settings: Settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  adaptiveHeight: true,
  lazyLoad: 'ondemand',
};
const ImageCarousel: React.FC<IProps> = (props) => {
  const { media, autoPlay } = props;

  return (
    <Slider {...settings} autoplay={autoPlay} autoplaySpeed={5000}>
      {media
        ?.sort((a: string, b: string) => +a - +b)
        .map((url) => (
          <LazyImage
            src={`${getCompressedImageUrl(url)}?tr=h-357`}
            height="357px"
            width="100%"
            key={`${getCompressedImageUrl(url)}?tr=h-357`}
            alt="produit"
            className="object-fit"
            style={{
              marginBottom: '-8px',
              objectFit: 'cover',
              padding: '35px',
            }}
          />
        ))}
    </Slider>
  );
};

export default ImageCarousel;
