import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { queryStringToObject } from 'client/helpers/queryStringToObject';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'client/hooks/useAppSelector';
import { format } from 'date-fns';

export const useProductDescriptionCardContainer = () => {
  const [t] = useTranslation();

  const location = useLocation();

  const urlParams: { from: string; 'number-of-nights': string } = useMemo(
    () =>
      queryStringToObject<{ from: string; 'number-of-nights': string }>(
        decodeURI(location.search),
      ),
    [location.search],
  );

  const { cataloguePricesAvailability } = useAppSelector(
    ({ productReducer }) => productReducer,
  );

  const { cataloguePrices } = useAppSelector(({ productReducer }) => productReducer);
  const pricesList = cataloguePrices && cataloguePrices.prices;

  const minimumPrice =
    cataloguePrices &&
    cataloguePrices.prices
      .reduce(
        (prev, curr) => (Number(curr.value) < prev ? Number(curr.value) : prev),
        Infinity,
      )
      .toString();

  const initialValues = useMemo(() => {
    const from =
      (urlParams.from &&
        cataloguePricesAvailability?.find((el) => el.from?.value === urlParams.from)?.from) ||
      cataloguePricesAvailability?.find((el) => el.from?.value === cataloguePrices?.from.value)
        ?.from ||
      cataloguePricesAvailability?.[0].from;

    const numberOfNights =
      (urlParams['number-of-nights'] &&
        cataloguePricesAvailability
          ?.find((el) => el.from?.value === from?.value)
          ?.nights.find((e) => e.value === urlParams['number-of-nights'])) ||
      cataloguePricesAvailability?.find((el) => el.from?.value === cataloguePrices?.from.value)
        ?.nights?.[0].value ||
      cataloguePricesAvailability?.find((el) => el.from?.value === from?.value)?.nights?.[0]
        .value;

    const startDate =
      pricesList?.length !== 0 &&
      format(
        new Date(pricesList?.filter((e) => e.value === minimumPrice)?.[0].date || 0),
        'yyyy-MM-dd',
      );

    const price = pricesList && pricesList.filter((e) => e.value === minimumPrice)?.[0]?.value;

    return { from, numberOfNights, startDate, price };
  }, [
    urlParams,
    cataloguePricesAvailability,
    pricesList,
    cataloguePrices?.from.value,
    minimumPrice,
  ]);

  return { t, initialValues };
};
