import React from 'react';
import Modal from 'client/components/Modal';
import InputPlusMinusButtons from '../InputPlusMinusButtons';
import { useSelectListModalVoyageur } from './useSelectListModalVoyageur';

interface IProps {
  label: string;
  classNameIcon?: string;
}

const SelectListModalVoyageur: React.FC<IProps> = ({ label, classNameIcon }) => {
  const { handleClose, open, setOpen, t, watch } = useSelectListModalVoyageur();

  return (
    <div className="mb-3">
      <div className={`${open && 'show-input-open-modal bg-white position-relative'}`}>
        <div className={`font-size-1 ${open && 'bg-modal-color text-white px-2'}`}>
          {label}
        </div>

        <div
          className={`border d-flex px-2 py-2 w-100 justify-content-between  ${
            open && 'border-primary '
          }`}
          onClick={() => setOpen(true)}
          style={{ cursor: 'pointer' }}
        >
          <div
            className="  d-flex align-items-center justify-content-center text-primary font-weight-bold"
            style={{ fontSize: '14px' }}
          >
            <i className={`${classNameIcon} mr-2`} />
            {`${watch('adultCount')} adulte(s) | ${watch('childCount')} enfant(s)`}
          </div>
          <div
            className="d-flex align-items-center justify-content-center font-weight-bold text-dark"
            style={{
              fontSize: '10px',
            }}
          >
            Modifier
          </div>
        </div>
      </div>
      <Modal
        closeModal={() => handleClose()}
        isOpen={open}
        modalHead={label}
        isOptionalClose
        modalHeadStyle="px-5 py-3 z-index-999 position-fixed bg-primary d-inline-flex 
        text-white align-item-center justify-content-between row no-gutters w-30"
        buttonCloseStyle="text-white"
        modalClassName="w-md-20 w-100 align-items-start align-content-md-center"
      >
        <div
          className="d-flex list-group rounded-0 mb-0 p-2 w-100 mt-4"
          style={{ background: '#f9f8f8' }}
        >
          <div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="font-size-1 col-6">
                <p>
                  {t('ProductDetailsPriceStepOne.Adult').toUpperCase()}: <span>(12+)</span>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-2 col-6">
                <InputPlusMinusButtons min={1} max={6} name="adultCount" defaultValue={2} />
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              <div className="font-size-1 col-6">
                <p>
                  {t('ProductDetailsPriceStepOne.Child').toUpperCase()}:
                  <span>(de 0 à 12 ans)</span>
                </p>
              </div>
              <div className="d-flex justify-content-end mt-2 col-6">
                <InputPlusMinusButtons min={0} max={6} name="childCount" />
              </div>
            </div>
          </div>
          <button
            type="button"
            onClick={handleClose}
            className="btn btn-primary w-100 mx-auto w-md-auto my-3"
          >
            {t('quotedAvailabilityPage.Confirmation').toUpperCase()}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default SelectListModalVoyageur;
