/* eslint-disable no-nested-ternary */
import React, { ReactNode, useEffect, useState } from 'react';
// import './Modal.css';

export interface IModalProps {
  isOpen?: boolean;
  modalHead?: string;
  modalHeadStyle?: string;
  modalContent?: string;
  buttonCloseStyle?: string;
  footer?: ReactNode;
  closeModal: () => void;
  modalClassName?: string;
  isOptionalClose?: boolean;
  isScrollable?: boolean;
  flightOptions?: boolean;
}

const Modal: React.FC<IModalProps> = ({
  isOpen = false,
  closeModal,
  modalHead,
  footer,
  children,
  modalHeadStyle,
  buttonCloseStyle,
  modalClassName,
  isOptionalClose,
  isScrollable,
  flightOptions,
}) => {
  const [isDelayedChange, setIsDelayedChange] = useState<boolean>(false);
  const [isOpening, setIsOpening] = useState<boolean>(true);

  useEffect(() => {
    if (isOpen === true) setIsOpening(true);
    else setIsOpening(false);
    setTimeout(() => {
      setIsDelayedChange(isOpen);
    }, 150);
  }, [isOpen]);

  const widthModal = {
    width: '30% !important',
  };

  return (
    <div className={isOpening ? 'modal-open' : ''}>
      <div
        data-testid="modalDisplay"
        style={{ display: isOpening ? 'block' : isDelayedChange ? 'block' : 'none' }}
        className={
          !isOpening ? 'modal fade' : isDelayedChange ? 'modal fade show' : 'modal fade '
        }
      >
        <div
          data-testid="modalBg"
          role="button"
          onClick={() => !isOptionalClose && !flightOptions && closeModal()}
          onKeyDown={() => !isOptionalClose && closeModal()}
          tabIndex={0}
          className="modal-bg"
        >
          <div
            className={`modal-container container  ${isScrollable ? 'scroll-section' : ''} ${
              modalClassName || ''
            }  ${modalHeadStyle && 'rounded-0 p-0'}`}
            style={{ left: 'auto', top: 'auto' }}
          >
            <div
              className={`${modalHeadStyle || 'modal-header p-0 border-bottom h5'}`}
              style={widthModal}
            >
              <div>{modalHead}</div>
              <button type="button" className="close" aria-label="Close" onClick={closeModal}>
                <span
                  className={`${buttonCloseStyle || 'font-size-4 font-weight-light '}`}
                  aria-hidden="true"
                >
                  ×
                </span>
              </button>
            </div>
            <div className="space-top-1">{children}</div>
            {footer && <div className="space-top-1 ">{footer}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
